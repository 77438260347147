<template>
  <div class="inner-section application-form-wrapper">
    <bread-cumb />
        <body-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('lrcpnServicePanel.acid_license_clearance_application') }}</h4>
            </template>
            <template v-slot:body>
                <b-overlay :show="loading">
                <form-wizard shape="circle" title="" subtitle="" :start-index.sync="activeIndex">
                    <tab-content :title="$t('lrcpnServicePanel.applicant_info')" icon="ri-file-user-line" :before-change="tabSubmitApplicantInfo">
                        <ApplicantForm :key="formData" :tabFormData="formData" ref="applicant_info_form"/>
                    </tab-content>
                    <tab-content :title="$t('globalTrans.attachment')" icon="ri-file-cloud-line" :before-change="tabAttachment">
                        <AttachmentForm :key="formData.attachment" :tabFormData="formData.attachment" ref="attachment_form" :draft="draft"/>
                    </tab-content>
                    <template slot="footer" slot-scope="props">
                            <div class="wizard-footer-right">
                                <wizard-button class="btn btn-secondary" @click.native="props.prevTab(), step--"  v-if="props.activeTabIndex > 0" :style="props.fillButtonStyle">{{ $t('globalTrans.back') }}</wizard-button>
                                <wizard-button
                                    type="submit"
                                    class="btn btn-primary ml-1 mr-1"
                                    title="Create Draft and Save!"
                                    @click.native="props.nextTab(), draft = 1"
                                >
                                <i class="ri-draft-line"></i> {{$t('globalTrans.save_draft')}}
                                </wizard-button>
                                <wizard-button
                                    type="submit"
                                    v-if="!props.isLastStep"
                                    @click.native="props.nextTab(), isNext = true"
                                    class="btn btn-success ml-1 mr-1"
                                    title="Draft and go to next!"
                                    :style="props.fillButtonStyle"
                                ><i class="ri-save-2-line"></i> {{$t('globalTrans.draft_n_next')}}
                                </wizard-button>
                                <wizard-button
                                    v-else
                                    type="submit"
                                    class="btn btn-success ml-1"
                                    title="Submit!"
                                    @click.native="finalSave(), app_status = 2"
                                >
                                <i class="ri-send-plane-fill"></i> {{ $t('globalTrans.finalSave')}}
                                </wizard-button>
                                <wizard-button
                                    type="button"
                                    class="btn btn-danger ml-1 mr-1"
                                    title="Cancel"
                                >
                                    <router-link :to="{ name: 'lrcpn_service.acid_license.acid_license_list' }" class="text-white"><i class="ri-close-circle-line"></i> {{ $t('globalTrans.cancel') }}</router-link>
                                </wizard-button>
                            </div>
                    </template>
                </form-wizard>
                </b-overlay>
            </template>
        </body-card>
  </div>
</template>
<script>
  import BreadCumb from '@/components/BreadCumb.vue'
  import { FormWizard, TabContent } from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import WizardButton from 'vue-form-wizard/src/components/WizardButton.vue'
  import ApplicantForm from './ApplicantForm.vue'
  import AttachmentForm from './AttachmentForm'
  import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
  import { acidLicenseClearanceApplicationShowApi } from '../../api/routes'
  export default {
      components: { BreadCumb, FormWizard, TabContent, WizardButton, ApplicantForm, AttachmentForm },
      data () {
          return {
              activeIndex: 0,
              baseUrl: lrcpnServiceBaseUrl,
              formData: {
                  id: 0,
                  user_id: null,
                  attachment: {
                    app_id: 0
                  },
                  import_infos: []
              },
              status: 2,
              showDraftButton: true,
              isRenew: false,
              draft: 0
          }
      },
      created () {
          if (this.$route.params.id) {
              this.getFormData(this.$route.params.id)
          }
      },
      computed: {
          loading: function () {
              return this.$store.state.commonObj.loading
          }
      },
      methods: {
        finalSave () {
            this.$swal({
                title: this.$t('globalTrans.final_save_msg'),
                showCancelButton: true,
                confirmButtonText: this.$t('globalTrans.yes'),
                cancelButtonText: this.$t('globalTrans.no'),
                focusConfirm: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.tabAttachment()
                } else {
                    this.app_status = 1
                }
            })
        },
        async tabSubmitApplicantInfo () {
            const result = await this.$refs.applicant_info_form.submit()
            if (result.success) {
                this.formData = Object.assign({}, this.formData, result.model)
                if (!this.$route.params.id) {
                    this.formData.attachment = Object.assign(
                        {},
                        this.formData.attachment,
                        { app_id: result.data.id }
                    )
                }

                if (this.draft) {
                    this.$router.push({ name: 'lrcpn_service.acid_license.acid_license_list' })
                } else {
                    return true
                }
            }
        },
        async tabAttachment () {
            const result = await this.$refs.attachment_form.submit()
            if (result.success) {
                this.$router.push({ name: 'lrcpn_service.acid_license.acid_license_list' })
            }
        },
        async getFormData (id) {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            var result
            result = await RestApi.getData(lrcpnServiceBaseUrl, acidLicenseClearanceApplicationShowApi + '/' + id)
            if (result.success) {
                this.formData = result.data
                this.formData.attachment = Object.assign({}, this.formData.attachment, { app_id: result.data.id })
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false })
        }
      }
  }
</script>
